.admin-dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #343a40;
}

.loading, .access-denied, .no-requests {
  font-size: 18px;
  text-align: center;
  color: #6c757d;
}

.verification-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.verification-item {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
}

.verification-info {
  margin-bottom: 15px;
}

.verification-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #495057;
}

.verification-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 15px;
}

.verification-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.approve-button, .reject-button {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.approve-button {
  background-color: #28a745;
  color: #ffffff;
  margin-right: 10px;
}

.approve-button:hover {
  background-color: #218838;
}

.reject-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reject-reason-select, .custom-message-textarea {
  margin-bottom: 10px;
  font-size: 14px;
}

.reject-reason-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.custom-message-textarea {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  resize: vertical;
}

.reject-button {
  background-color: #dc3545;
  color: #ffffff;
}

.reject-button:hover {
  background-color: #c82333;
}
