.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    text-align: center;
    padding-bottom: 40px;
    margin-top: 50px;
  }
  .signup-container p {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .age_warn {
    font-size: 13px;
    color: gray;
  }
  
  .signup-form {
    flex-direction: column;
  }
  
  .signup-input,
  .signup-select {
    width: 80%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .signup-input::placeholder {
    color: #aaa;
  }
  
  .signup-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 80%;
    margin-top: 20px;
  }
  
  .signup-button:hover {
    background-color: #0d8ae1;
  }
  
  .google-signup-button {
    padding: 10px 20px;
    background-color: #db4437;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 15px;
  }
  
  .google-signup-button:hover {
    background-color: #c23321;
  }
  
  .error-message-k {
    color:red;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  