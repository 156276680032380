.inbox-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    margin: 0 auto;
    margin-top: 0px;
    padding-bottom: 50px;
    max-width: 700px;
    padding-top: 0px;
  }
  .inbox-top img{
    width: 100%;
  }
  .inbox-top {
    text-align: left;
  }
  .top_text {
    font-size: 22px;;
    margin-bottom: 0px;;
    font-weight: bold;
    padding-bottom: 0px;

  }
  .chat-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0px;
  }
  
  .chat-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 0;
    transition: background-color 0.3s ease;
  }
  
  .chat-item:hover {
    background-color: #f1f1f1;
  }
  
  .chat-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 100%;
  }
  
  .chat-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .chat-avatar:hover {
    transform: scale(1.1);
  }
  
  .chat-info {
    flex-grow: 1;
    position: relative;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-name {
    font-size: 1.2em;
    margin: 0;
  }
  
  .chat-date {
    font-size: 0.8em;
    color: #888;
  }
  
  .chat-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-last-message {
    margin: 5px 0;
    font-size: 0.9em;
    flex-grow: 1;
  }
  
  .chat-unread-count {
    background-color: red;
    color: white;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 0.8em;
    margin-left: 10px;
    white-space: nowrap;
  }

  .unread-badge-a {
    background-color: red;
    color: white;
    padding: 2px 5px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    margin-left: 8px;
    font-weight: bold;
  }
  
  