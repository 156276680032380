/* 全体のコンテナ */
.rindoku-create-container {
    max-width: 650px;
    margin: 0 auto;
    padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 50px;
  }
  
  /* ヘッダー */
  .rindoku-create-container .settings-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .rindoku-create-container .settings-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* グループ作成フォーム全体のスタイル */
  .rindoku-create-group-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    width: 100%;
  }
  
  /* 入力フィールドのスタイル */
  .rindoku-create-group-input,
  .rindoku-create-group-textarea,
  .rindoku-create-search-input {
    width: 70%;
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
  }
  
  .rindoku-create-group-textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  /* フォーカス時のスタイル */
  .rindoku-create-group-input:focus,
  .rindoku-create-group-textarea:focus,
  .rindoku-create-search-input:focus {
    outline: none;
    border: 1px solid #4CAF50;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
  }
  
  /* 検索フォームのスタイル */
  .rindoku-create-book-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0px;
    border-radius: 12px;
    max-width: 600px;
    margin: 0 auto;
    width: 99%;
  }
  
  /* 検索ボタンのスタイル */
  .rindoku-create-search-button {
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .rindoku-create-search-button:hover {
    background-color: #45a049;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 検索結果の表示エリア */
  .rindoku-create-search-results {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4列のレイアウト */
    gap: 20px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .rindoku-create-search-results {
      grid-template-columns: repeat(2, 1fr); /* 2列のレイアウト */
    }
  }
  
  /* 検索結果アイテムのスタイル */
  .rindoku-create-search-result-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
    cursor: pointer;
    text-align: left;
  }
  
  .rindoku-create-search-result-item:hover {
    border: 2px solid #4CAF50;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rindoku-create-search-result-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 3px;
    border-radius: 4px;
  }
  
  .rindoku-create-search-result-item h3 {
    font-size: 13px;
    margin-bottom: 4px;
    color: #333;
  }
  
  .rindoku-create-search-result-item p {
    font-size: 12px;
    color: #666;
    margin: 0px;
    padding: 0px;
  }
  
  /* 選択されたアイテムの強調表示 */
  .rindoku-create-search-result-item.selected {
    border: 2px solid #4CAF50;
    background-color: #f1f8e9; /* 選択時の背景色 */
  }
  
  /* 選択された本の表示 */
  .rindoku-create-container .selected-book {
    text-align: center;
    margin-top: 20px;
  }
  
  .rindoku-create-container .selected-book h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .rindoku-create-container .selected-book img {
    max-width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .rindoku-create-container .selected-book p {
    font-size: 12px;
    font-weight: bold;
  }
  
  /* グループ作成ボタン */
  .rindoku-create-container .create-group-button {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .rindoku-create-container .create-group-button:hover {
    background-color: #45a049;
  }
  
  /* ローディングメッセージ */
  .rindoku-create-container p {
    text-align: center;
    font-size: 16px;
    color: #555;
  }
  /* 検索フォーム全体をFlexboxで横並びにする */
.rindoku-create-book-search {
    display: flex;
    gap: 0px; /* 入力とボタンの間のスペース */
    margin-bottom: 20px;
  }
  
  /* 検索入力フィールド */
  .rindoku-create-search-input {
    flex: 1; /* 入力フィールドを最大限広げる */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  /* 検索ボタン */
  .rindoku-create-search-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rindoku-create-search-button:hover {
    background-color: #45a049;
  }
  .indoku-create-book-searchx h5{
    width: 70%;

  }

  .book-result {
    position: relative;
    cursor: pointer;
    padding: 10px;
    border: 2px solid transparent; /* デフォルトでは枠線なし */
    transition: border 0.3s ease;
  }

  .book-result:hover {
    border: 2px solid #ccc; /* ホバー時の枠線 */
  }
  
  .book-result.selected {
    border: 2px solid #007BFF; /* 選択時に青色の枠線を追加 */
    background-color: #f0f8ff; /* 選択時に背景色を追加 */
  }
  
  .selected-label {
    position: absolute;
    top: 10px; /* 上からの位置 */
    right: 10px; /* 右端に配置 */
    background-color: #007BFF; /* 背景色を指定 */
    color: white; /* 文字色を白に */
    padding: 5px 10px; /* パディングを追加 */
    border-radius: 4px; /* 角を丸く */
    font-size: 12px; /* フォントサイズを調整 */
  }
  