.age-verification-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}



.age-verification-container input[type="file"] {
  display: block;
  margin: 20px auto;
  padding: 10px;
}

.age-verification-container button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.age-verification-container button:hover {
  background-color: #007bff;
}

.checking-message, .checked-message, .error-message, .success-message {
  text-align: center;
  margin-top: 20px;
  font-size: 17px;
  padding: 15px;
  font-weight: bold;
  border-radius: 15px;
  background-color: #f5f8fa;
  text-align: left;
}

.error-message {
  color: #e0245e;
}

.success-message {
  color: #17bf63;
}

.note-bottom {
  margin-top: 20px;
  background-color: #f5f8fa;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  color: #657786;
}

.note-bottom p {
  margin: 0 0 10px;
  font-weight: bold;
}

.note-bottom ul {
  padding-left: 20px;
}

.note-bottom li {
  margin-bottom: 5px;
}

.note-top {
  margin-top: 20px;
  background-color: #f5f8fa;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  color: black;
  text-align: center;
}

.note-top p {
  margin: 0 0 10px;
  font-weight: bold;
  text-align: left;
}

.note-top ul {
  padding-left: 20px;
}

.note-top h3{
  text-align: left;
  margin-top: 20px;;
}

.note-top li {
  margin-bottom: 5px;
}

.note-top img {
  width: 250px;
}
