.menu-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  padding: 13px 0;
  z-index: 1000;
  padding-left: 4px;
  padding-bottom: 10px;
}

.menu-bar link {
  width: 100px;
}

a {
  text-decoration: none;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  position: relative; /* 赤点の位置を調整するために追加 */
}

.menu-item img {
  width: 23px;
  height: 23px;
  margin-bottom: 5px;
}

.menu-item span {
  font-size: 12px;
}

.unread-badge {
  position: absolute;
  top: -3px;
  right: -3px;;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}
.unread-badge-iine {
  position: absolute;
  top: 0;
  right: -6px;;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

