/* 全体のスタイル */
.custom-search-container {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  max-width: 600px;
  margin: 0 auto;
}
.custom-results-list {
  margin: 0 auto;
  width: 100%;
}

.custom-results-list-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-flow: dense;
  gap: 30px;
  padding: 10px;
  margin: 0 auto;
  width: 88%;
}

.custom-results-list-content-a {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-flow: dense;
  gap: 0px;
  padding: 10px;
  margin: 0 auto;
  width: 98%;
}

/* 各検索結果のアイテムをスタイル */
.custom-result-item {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

/* 各検索結果のアイテムをスタイル */
.custom-result-item-a {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  width: 130px;
  margin-bottom: 20px;
}

.custom-result-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.custom-result-item:hover {
  transform: translateY(-5px); /* ホバー時にアイテムを少し持ち上げる */
}

.custom-result-item:hover img {
  transform: scale(1.05); /* ホバー時に画像を拡大 */
}

.custom-result-item h3 {
  font-size: 0.9rem;
  margin: 3px;
  font-weight: bold;
  color: #333;
  margin-left: 3px;
  margin-bottom: 0px;
}

.custom-result-item p {
  font-size: 0.7rem;
  color: #666;
  margin: 3px;
  margin-left: 3px;
  margin-top: 0px;
}

.custom-result-item-a h3 {
  font-size: 0.9rem;
  margin: 3px;
  font-weight: bold;
  color: #333;
  margin-left: 3px;
  margin-bottom: 0px;
  padding: 1px;
}

.custom-result-item-a p {
  font-size: 0.7rem;
  color: #666;
  margin: 3px;
  margin-left: 3px;
  margin-top: 0px;
}


/* ボタンのスタイル */
.custom-write-review-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  border-radius: 0 0 12px 12px;
  transition: background-color 0.3s ease;
  margin-top: auto; /* ボタンを下部に固定 */
  font-size: 11px;
  font-weight: bold;
}

.custom-write-review-button:hover {
  background-color: #007bff;
}

/* シンプルでおしゃれな停止ボタンのスタイル */
.custom-stop-music-button {
  width: 20px;
  height: 20px;
  background-color: #2196F3;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0;
  font-weight: bold;
  margin-left: 10px; /* 再生ボタンとの間にスペースを作る */
}

.custom-stop-music-button:hover {
  background-color: #1976D2;
}


/* ローディングとエラーメッセージのスタイル */
.custom-loading {
  text-align: center;
  font-size: 1.5rem;
  color: #888;
  padding: 20px;
}

.custom-error {
  text-align: center;
  font-size: 1.2rem;
  color: red;
  padding: 20px;
}

/* 検索バーのスタイル */
.custom-search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.custom-search-bar input {
  width: 160px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
}

.custom-search-bar select {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
}

.custom-search-bar button {
  padding: 10px 5px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 60px;
}

.custom-search-bar button:hover {
  background-color: #007bff;
}

/* シンプルでおしゃれな再生ボタンのスタイル */
.custom-play-music-button {
  width: 20px;
  height: 20px;
  background-color: #f44336;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0;
  margin-left: 5px;
}

.custom-play-music-button:hover {
  background-color: #d32f2f;
}

/* フッタークレジットのスタイル */
.custom-credit {
  text-align: center;
  padding: 0px;
  font-size: 0.9rem;
  color: #888;
}

.custom-credit a {
  color: #2196F3;
  text-decoration: none;
}

.custom-credit a:hover {
  text-decoration: underline;
}

.my-favotites-works {
  text-align: center;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(250px); /* 中心から200px右に移動 */
}

.my-favotites-works-button {
  padding: 9px 10px 6px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .my-favotites-works {
    text-align: center;
    position: fixed;
    bottom: 100px;
    left: 100%;
    transform: translateX(-50%);
  }
  
}

