.timeline-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 150px;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 35px;
}

.filter-buttons button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-buttons button:hover {
  background-color: #007BFF;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

h7 {
  font-size: 11px;
  color: gray;
}

.filter-buttons button:focus {
  outline: none;
}

.filter-buttons button:active {
  background-color: #0056b3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



.app-title {
  font-size: 24px;
  font-weight: bold;
}

.post-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.post-form textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 80%;
  resize: none;
  margin: 0 auto;
}

.post-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.post-button:hover {
  background-color: #0056b3;
}

.posts-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post-item {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding-bottom: 3px;
}

.post-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-name {
  font-weight: bold;
  font-size: 16px;
}

.post-date {
  font-size: 12px;
  color: gray;
}

.post-text {
  margin-bottom: 10px;
  font-size: 16px;
}

.post-image {
  max-width: 100%;
  max-height: 300px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 10px;
}

.post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.like-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #007bff;
  font-size: 14px;
}

.edit-delete-buttons {
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
}

.edit-button:hover,
.delete-button:hover {
  color: #007bff;
}

textarea {
  width: 80%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.edit-button,
.cancel-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover,
.cancel-button:hover {
  background-color: #0056b3;
}

.reply-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #007bff;
  transition: color 0.3s ease;
}

.reply-button:hover {
  color: #007bff;
}

.reply-form {
  margin-top: 10px;
}

.reply-form textarea {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 80%;
  resize: none;
  margin-bottom: 10px;
}

.reply-form .reply-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reply-form .reply-button:hover {
  background-color: #007bff;
}

.replies {
  margin-top: 20px;
}

.reply-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.reply-text {
  font-size: 14px;
  margin-bottom: 5px;
}

.reply-date {
  font-size: 12px;
  color: gray;
  text-align: right;
}

.custom-file-input {
  display: none;
}

.custom-file-label {
  display: inline-block;
  padding: 10px;
  color: gray;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 10px;
}

.file-name {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}
.post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between; /* ボタンを左右に分ける */
  gap: 10px; /* アイコンとボタンの間に余白を追加 */
  padding-top: 10px;
}

.like-button,
.reply-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
}

.like-button:hover,
.reply-button:hover {
  color: #0056b3;
}

.edit-delete-buttons {
  display: flex;
  gap: 10px; /* 編集と削除ボタンの間にスペースを作る */
}

.edit-button,
.delete-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
}

.edit-button:hover,
.delete-button:hover {
  color: #ff4d4d;
}
.file-input-container {
  display: flex;
  align-items: center;
  gap: 0px; /* 要素間のスペースを調整 */
}

.custom-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: gray;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 30px;
  height: 40px; /* 正方形にして中央揃え */
  margin: 0px;
}
.file-name {
  font-size: 14px;
  color: #333;
  margin-left: 10px;
}

.post-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.post-button:hover {
  background-color: #0056b3;
}

.reply-item {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.reply-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.reply-user-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.reply-date {
  font-size: 12px;
  color: #888;
}

.reply-text {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  margin-top: 5px;
}
.delete-reply-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 5px;
  transition: color 0.3s;
}

.delete-reply-button:hover {
  color: #e60000;
}
.delete-reply-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff4d4d;
  margin-left: auto; /* 右端に配置するために追加 */
  display: flex;
  align-items: center;
}

.delete-reply-button:hover {
  color: #ff0000;
}
.file-input-container {
  display: flex;
  align-items: center;
  gap: 10px; /* アイコンとファイル名の間にスペースを追加 */
}

.custom-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  color: gray;
}

.file-name {
  flex-grow: 1;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.post-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.post-button:hover {
  background-color: #0056b3;
}
/* プレビュー画像のスタイル */
.image-preview {
  display: block;
  margin-top: 10px;
  max-width: 30px; /* プレビュー画像の最大幅 */
  max-height: 30px; /* プレビュー画像の最大高さ */
  border-radius: 10px; /* 角を丸める */
  object-fit: cover; /* 画像がボックス内に収まるようにする */
  border: 1px solid #ddd; /* ボーダーを付ける */
  background-color: whitesmoke;
  padding: 8px;;
}

/* プレビュー画像を中央に配置 */
.file-input-container {
  display: flex;
  align-items: center;
  gap: 10px; /* プレビュー画像と他の要素の間にスペース */
}

.custom-file-label {
  cursor: pointer;
  display: inline-block;
}

.file-name {
  margin-top: 10px;
  font-size: 12px;
  color: #333;
}

/* 長いリンクやテキストを折り返し、レイアウトが崩れないようにする */
.post-text {
  word-wrap: break-word; /* 長い単語やリンクが折り返される */
  white-space: pre-wrap; /* 改行を保持しつつ折り返し */
  line-height: 1.5;
  font-size: 16px;
}

/* 投稿内のリンクをスタイリング */
.post-text a {
  color: #007bff; /* リンクの色 */
  text-decoration: underline; /* リンクに下線を付ける */
  word-break: break-all; /* 長すぎるリンクを折り返す */
}

/* リンクのホバー効果 */
.post-text a:hover {
  color: #0056b3; /* ホバー時の色 */
}

/* コメントや返信内のリンクも同様にスタイルを適用 */
.reply-text,
.nested-reply-text {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.reply-text a,
.nested-reply-text a {
  color: #007bff;
  text-decoration: underline;
  word-break: break-all;
}

/* 右下に固定されるユニークなボタン */
.timeline-floating-button {
  position: fixed;
  background-color: #1da1f2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
  bottom: 100px;
}

.timeline-floating-button:hover {
  background-color: #1a91da;
}

/* モーダル背景 */
.timeline-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 背景を暗く */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 最前面に表示 */
}

/* モーダル内容 */
.timeline-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: right;
  padding-bottom: 10px;
}

/* 閉じるボタン */
.timeline-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* テキストエリアの高さが自動的に変わる */
.timeline-auto-expand {
  resize: none; /* 手動でのリサイズを無効化 */
  overflow: hidden; /* スクロールバーを非表示にする */
  min-height: 100px; /* 最小高さ */
  max-height: 300px; /* 最大高さ */
  line-height: 1.5;
  white-space: pre-wrap; /* 改行を保持 */
}

/* 右下に固定されるユニークなボタン */
.timeline-floating-button {
  position: fixed;
  background-color: #1da1f2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
  bottom: 100px;
}

.timeline-floating-button:hover {
  background-color: #1a91da;
}


