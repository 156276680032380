.admin-inquiry-container {
  padding: 20px;
}

.inquiry-item {
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inquiry-item:hover {
  background-color: #e8e8e8;
}

.inquiry-date {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  color: #888;
}

.response-form {
  margin-top: 20px;
}

.response-form h3 {
  margin-bottom: 10px;
}

.response-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.response-form button {
  padding: 10px 20px;
}
