/* /pages/LandingPage.css */
.all-container {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    max-width: 550px;
  }

/* /pages/LandingPage.css */
.container {
    color: #fff;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 0px;
    max-width: 500px;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  
  .header-top {
    padding: 0rem 2rem;
    background-color: white;
    text-decoration: none; /* 下線を消したい場合 */
    color: black;
    position: absolute;
    top: 5px;
    margin: 0 auto;
    max-width: 500px;
  }

  .button-b {
    position: relative;
    top: 0px;
    padding: 0.2rem 1rem;
    font-size: 1rem;
    color: #fff;
    background: #007bff;
    border: none;
    border-radius: 1.5rem;
    cursor: pointer;
    font-weight: bold;
  }
  
  .logo {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    color: black;
    margin-bottom: 8px;
    margin-top: 6px;
    font-weight: bold;
    position: relative;
    left: -1px;
  }

  .header-container {
    display: flex;
    justify-content: space-between; /* 左右に要素を配置 */
    align-items: center; /* 要素を上下中央に配置 */
    padding: 0 20px; /* 任意の余白を追加 */
  }
  
  .logo img {
    height: 30px; /* ロゴ画像の高さを設定（任意） */
  }


  .lp-top-img {
    width: 100%;
  }
  
  
  .logo img {
    width: 40px;
    height: 30px;
    margin-right: 0.5rem;
    margin-top:5px;
  }
  
  
  .button-a {
    padding: 0.5rem 1.4rem;
    font-size: 1.2rem;
    color: #fff;
    background: #007bff;
    border: none;
    border-radius: 1.5rem;
    cursor: pointer;
    font-weight: bold;
  }
  
  .button:hover {
    background: #0056b3;
  }

  .button-container {
    width: 160px;
    margin: 0 auto;
    text-align: center;
  }
  
  .footer {
    padding: 1rem 2rem;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 70px;
  }
  
  .footer-text {
    font-size: 0.875rem;
    color: #fff;
  }

  .footer-text a {
    color: white;
    text-decoration: none; /* 下線を消したい場合 */
  }
  
  .footer-text a:hover {
    color: #f0f0f0; /* ホバー時に少し色を変えたい場合 */
  }
  

  /* オシャレな説明用のdiv */
.description-container {
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    max-width: 90%;
    margin: 20px auto; /* 中央に配置 */
    text-align: left;
    padding: 14px;
    margin-top: 100px;
  }
  
  .description-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .description-text {
    font-size: 1.2rem;
    color: #0056b3;
    line-height: 1.6;
    font-weight: bold;
    width: 85%;
    margin: 0 auto;
    margin-top: 20px;
    text-align: left;
    margin-bottom: 35px;
  }

  .about-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2列のグリッド */
    gap: 10px;
    margin-top: 0px;
    padding: 10px;
    text-align: center;
  }
  
  .feature {
    background-color: #ffffffcc; /* 半透明の白背景 */
    padding: 7px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
    padding-top: 0px;
  }

  .feature p{
    text-align: left;
    margin: 10px auto;
    width: 95%;
  }
  
  .feature:hover {
    transform: translateY(-5px); /* ホバー時に少し浮き上がる効果 */
  }
  
  .feature-icon {
    width: 130px;
    height: 130px;
    margin-bottom: 10px;
    margin: 0 auto;
    border-radius: 15px;
  }
  
  .feature-text {
    font-size: 1rem;
    color: #333;
    font-weight: bold;
  }
  .price-table-container {
    margin-top: 170px;
    text-align: center;
  }

  .price-table-container h5{
    text-align: left;
    margin: 0 auto;
    width: 90%;
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .price-table-container h4{
    font-size: 1.1rem;
  }
  
  
  .price-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 auto;
    width: 90%;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .price-table {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .price-item {
    background-color: #ffffffcc; /* 半透明の白背景 */
    padding: 0px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    width: 40%;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .price-item:hover {
    transform: translateY(-5px); /* ホバー時に少し浮き上がる効果 */
  }
  
  .price-icon {
    width: 90px;
    height: 50px;
    margin-bottom: 11px;
  }
  
  .price-category {
    font-size: 1.3rem;
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
  }
  
  .price-amount {
    font-size: 1.5rem;
    color: #007bff;
    font-weight: bold;

  }
  
  .recommendation-slider {
    margin-top: 3dvh;
    text-align: center;
    position: relative;
    top: 80px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    background-color: white;
    margin-left: 30px;
  }

  .voices-slider {
    margin-top: 3dvh;
    text-align: center;
    position: relative;
    top: 120px;
    padding-bottom: 60px;
    margin-bottom: 140px;
    background-color: white;
    margin-left: 30px;
  }

  .voices-slider h5{
    position: relative;
    top: -110px;
    text-align: left;
    font-size: 1.1rem;
    width: 90%;
  }

  .voices-slider h3{
    position: relative;
    top: -110px;
    text-align: left;
    font-size: 1.1rem;
    width: 90%;
  }
  
  .recommendation-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0px;
    position: relative;
    top: -30px;
    background-color: white;
    left: -10px;
    text-align: left;
  }
  
  .slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 20px;
    padding: 10px 50px;
    background-color: white;
    margin-top: 20px;
    
  }
  .slide h4{ 
    text-align: left;
  }

  .slide {
    max-width: 290px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: le;
    scroll-snap-align: start;
    flex-shrink: 0;
    transition: transform 0.3s ease;
    margin-left: 20px;
  }
  
  .slide:hover {
    transform: translateY(-5px);
  }
  
  .slide-image {
    width: 180px;
    margin-bottom: 0px;
    border-radius: 20px;
  }
  
  .slide-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .slide-description {
    text-align: left;
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  

  @media screen and (max-width: 600px) { 

  }
  
  
  