.member-search-container {
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  width: 65%;
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 900px;
}

.filter-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.filter-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.filter-button svg {
  margin-right: 8px;
}

.filter-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.filter-container .form-group {
  margin-bottom: 15px;
}

.filter-close-button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-close-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.member-grid {
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.member-card {
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.member-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.member-card img {
  width: 100%;
  height: auto;
  position: relative;
}

.member-info {
  padding: 5px;
  text-align: left;
  position: relative;
  bottom: 0px;
  background-color: white;
  opacity: 0.8;
  width: 100%;
  padding-bottom: 0px;
}

.member-name {
  font-weight: bold;
  font-size: 1.0em;
  margin-bottom: 8px;
  color: black;
  margin-left: 4px;
  margin-top: 0px;
  position: relative;
  top:-4px;
}

.member-major {
  font-size: 1em;
  color: #555;
  margin-bottom: 0px;
  position: relative;
  top:-5px;
  margin-left: 4px;
}

.member-time {
  font-size: 0.7em;
  color: #555;
  margin-bottom: 0px;
  position: relative;
  top:-7px;
  margin-left: 4px;
}

.member_hashtags {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  justify-content: left;
  margin-bottom: 8px;
  font-size: 13px;
  padding: 1px;
}

.member_hashtag {
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  padding: 2px 4px;;
  font-size: 12px;
  position: relative;
  top:3px;
  left:4px;
  font-weight:bold;
  margin-bottom: 3px;
  margin-right: 3px;;
}

.new-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: #fff;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 0.85em;
  z-index: 10;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 背景を暗くする */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 他のコンテンツの上に表示するためのz-index */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1010; /* overlayよりも上に表示 */
}
/* スライドボタンのコンテナ */
.slide-toggle-container {
  display: flex;
  position: relative;
  width: 100px;
  margin: 20px auto;
  border-radius: 30px;
  background: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* ボタンのスタイル */
.slide-toggle-button {
  flex: 1;
  text-align: center;
  padding: 8px ;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  z-index: 1;
}

.slide-toggle-button:hover {
  color: #007bff; /* ホバー時に色を変える */
  color: white;
}

/* スライダー（アクティブ表示） */
.slider-x {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  background: #007bff;
  transition: left 0.4s ease;
  border-radius: 30px;
  color: #fff;
}

/* 左右スライドアニメーション */
.slide-toggle-container .slide-toggle-button:nth-child(2):hover ~ .slider-x {
  left: 50%; /* 本検索ボタンをホバーしたとき右にスライド */
  color: white;
}

.slide-toggle-container .slide-toggle-button:nth-child(1):hover ~ .slider-x {
  left: 0%; /* ユーザー検索ボタンをホバーしたとき左にスライド */
}


@media (min-width: 1200px) {
  .member-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .member-grid {
    grid-template-columns: repeat(2, 1fr);
    .member-search-container {
      width: 78%;
    }
  }
}

@media (max-width: 599px) {
  .member-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .member-search-container {
    width: 88%;
  }
}
