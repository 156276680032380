.group-content-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  max-width: 650px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.group-content-top-img {
  width: 100%;
  height: 150px;
  object-fit: cover; /* 画像を正方形に保ちつつ、中央を表示 */
  border-radius: 8px; /* 角を丸くする */
  margin-bottom: 10px; /* 下に余白を追加 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 軽い影を付けて立体感を出す */
}


.thread-item {
  background-color: #fff;    
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 12px;
  margin-bottom: 20px;
  margin-top: 30px;
  border-radius: 10px;
  padding-bottom: 6px;
}

.thread-header {
  display: flex;
  align-items: center;
}

.thread-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.thread-details {
  flex-grow: 1;
}

.thread-timestamp {
  color: #777;
  font-size: 0.85rem;
}

.thread-text {
  margin-top: 10px;
}

.thread-actions {
  display: flex;
  justify-content: start;
  margin-top: 10px;
  padding-top: 3px;
  margin-bottom: 12px;
  margin-top: 0px;
  padding-bottom: 0px;
}

.like-button, .comment-button, .share-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 13px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.modal-content {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  background-color: #444;
  border: none;
  border-radius: 50%;
}

.thread-image {
  max-width: 60%;
  margin-top: 10px;
  cursor: pointer;
}


.group-content-input-form-container {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 0 auto;
}

.group-content-new-thread-input {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  margin-bottom: 10px;
}

.group-content-input-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-content-file-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.group-content-send-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.group-content-send-button:hover {
  background-color: #0056b3;
}

.group-content-file-preview {
  display: flex;
  align-items: center;
}

.group-content-file-preview-image {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
}

.group-content-remove-file-icon {
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.group-header-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.profile-scroll-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.profile-icon-link {
  display: inline-block;
  margin-right: 10px;
}

.profile-icon {
  width: 40px; /* Adjust size as necessary */
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* メニュー */
.group-content-top-menu {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 15px;
  text-decoration: none;
  color: black;
  width: 80%;
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 10px;
}

.group-content-top-menu-link {
  text-decoration: none;
  color: black;
  font-size: 15px;
  text-decoration: none;
}

.group-content-top-menu-link:hover {
  color: black;
  text-decoration: none;
}
.thread-user-name {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 500px) {

  .group-content-container {
    width: 93%;
    margin: 0 auto;
    padding: 5px;
    padding-top: 20px;
    margin-bottom: 60px;
  }
  .group-content-top-menu {
    width: 95%;
    margin-top: 10px;
    padding: 5px;
  }

  .group-content-top-menu-link {
    text-decoration: none;
    color: black;
    font-size: 13px;
    text-decoration: none;
  }
  .profile-icon {
    width: 30px; /* Adjust size as necessary */
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
}

/* CSS */
.add-thread-button {
  background-color: white;
  color: #007bff;
  border: 0.5px solid #007bff;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  margin: 0 auto;
}

.add-thread-button:hover {
  background-color: #62a6ee;
  color: white;
  font-weight: bold;
}

/* リプライのスタイル */
.group-content-reply-item {
  margin-left: 20px; /* インデントを追加 */
  padding-left: 15px;
  margin-top: 0px;
  background-color: white;
  border-radius: 5px;
}
.group-content-reply-item {
  position: relative; /* z-indexを有効にするために設定 */
  z-index: 1; /* 必要に応じて数値を設定 */
}

.group-content-reply-header {
  position: relative; /* 必要に応じて設定 */
  z-index: 1; /* ヘッダーが他の要素より上に表示されるように */
}
.group-content-reply-item {
  position: relative; /* 縦線を配置するために相対位置を指定 */
  padding-left: 20px; /* 縦線と内容の間にスペースを作る */
}


.group-content-reply-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-content-reply-user-photo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
}

.group-content-reply-details {
  flex: 1;
}

.group-content-reply-user-name {
  font-weight: bold;
  margin: 0;
  font-size: 0.9rem;
}


.group-content-reply-timestamp {
  font-size: 0.7rem;
  color: #888;
}

.group-content-reply-text {
  font-size: 0.9rem;
  margin: 5px 0;
  padding: 5px 15px;
  background-color: rgb(225, 224, 224);
  border-radius: 10px;
  display: inline-block; /* テキストの幅に合わせて表示を調整 */
  position: relative;
  left: -5px;
  z-index: 1;
}

.group-content-reply-actions {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  font-size: 0.9rem;
}

/* コメント入力フォームのスタイル */
.group-content-chat-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.group-content-chat-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.group-content-chat-send-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.group-content-chat-send-button:hover {
  background-color: #0056b3;
}

.group-content-reply-comment-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 0.9rem;
}

.group-content-reply-comment-button:hover {
  text-decoration: underline;
}
.reply-user-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}