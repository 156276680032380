@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/* 他のスタイル */
.other-member-profile-button {
  display: flex;
  align-items: center;
}

.like-icon {
  margin-left: 8px;
}

.other-member-profile-container {
  padding: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 30px;
  position: relative;
}

.arrow {
  position: absolute;
  top: 480px;
  transform: translateY(-90%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  width: 32px;
  height: 35px;
  font-size: 19px;
  border: 1px solid gray;
  padding:5px;
  padding-top: 3px;
}

.left-arrow {
  left: 10px;
  padding-right: 7px;
}

.right-arrow {
  right: 10px;
  padding-left: 7px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.other-member-profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.other-member-profile-image {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 20%;
  border: 5px white solid;
  max-width: 300px;
}

.other-member-sub-images {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 55%;
  margin: 0 auto;
  gap: 10px;
}

.other-member-sub-image {
  width: 28%;
  cursor: pointer;
  object-fit: cover;
}

.other-member-profile-name {
  font-size: 2em;
  margin-bottom: 6px;
}

.other-member-profile-bio-s {
  margin: 0px;
}

.other-member-profile-bio {
  font-size: 1.1em;
  margin-bottom: 10px;
  text-align: left;
  padding: 15px 30px 15px 30px;
  border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 0px;
    white-space: pre-wrap;
}

.other-member-profile-hashtags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  text-align: left;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.other-member-profile-hashtag {
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 0.9em;
  font-weight: bold;
}
.options-container {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 20px;
  width: 250px;
  text-align: left;
}

.options-icon {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
}

.options-menu {
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.options-menu div {
  padding: 8px 16px;
  cursor: pointer;
}

.options-menu div:hover {
  background-color: #f1f1f1;
}

.other-member-profile-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.other-member-profile-details p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.other-member-profile-actions {
  text-align: center;
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(220px); /* 中心から200px右に移動 */
}

.other-member-profile-button {
  padding: 10px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-weight: bold;
}

.other-member-profile-button.liked {
  background-color: #007bff; /* 好きな色に変更 */
  color: white;
  opacity: 30%;
}

.other-member-profile-button.liked:hover {
  color: #ffffff;
  opacity: 30%;
}


.other-member-profile-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.other-member-profile-approved {
  font-size: 1.2em;
  color: #28a745;
  font-weight: bold;
}

.other-reject {
  color: white;
  background-color: gray;
  font-size: 25px;
  position: relative;
  right: 40px;
}

.other-rejected {
  color: white;
  background-color: gray;
  font-size: 25px;
  position: relative;
  right: 20px;
  opacity: 0.3;
}

.other-approve {
  color: white;
  font-size: 25px;
  position: relative;
  right: 40px;
}

.iine-button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}




.profile-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 0px;
  background-color: white;
  margin-top: 35px;
}

.profile-tabs button {
  border: none;
  padding: 0px 0px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  color: rgb(181, 179, 179);
  background-color: white;
  width: 30%;

}

.profile-tabs .active-tab {
  color: black;
}

.profile-content {
  padding: 20px;
}

.user-post {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 7px 14px;
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
  margin-top: 10px;
}

.user-post:hover {
  transform: translateY(-5px);
}



.post-user-name {
  font-weight: bold;
  font-size: 14px;
}

.post-date {
  font-size: 12px;
  color: #888;
}

.post-content {
  margin-top: 10px;
}

.post-text {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.post-image {
  max-width: 100%;
  border-radius: 8px;
  object-fit: cover;
}




@media screen and (max-width: 600px) {
  .other-member-profile-image {
    width: 100%;
    height: 93%;
  }
  .other-member-profile-actions {
    text-align: center;
    position: fixed;
    bottom: 90px;
    left: 90%;
    transform: translateX(-50%);
  }
  
}
