.complete-profile-container {
  max-width: 400px;
  margin: 60px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.complete-profile-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.complete-profile-input,
.complete-profile-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
  margin-top: 10px;
}

.complete-profile-input:focus,
.complete-profile-select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.birthday-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.birthday-container select {
  flex: 1;
}

.complete-profile-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.complete-profile-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
