

/* プラン選択スタイル */
.plan-selection {
  margin-bottom: 20px;
}

.plan-selection h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.plan-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plan-options label {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.plan-options label:hover {
  background-color: #e0e0e0;
  border-color: #b0b0b0;
}

.plan-options input[type="radio"] {
  margin-right: 10px;
}

/* ボタンスタイル */
.subscribe-button {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:disabled {
  background-color: #007bff;
  cursor: not-allowed;
}

.subscribe-button:not(:disabled):hover {
  background-color: #007bff;
}

/* メッセージスタイル */
.success-message, .error-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}

.success-message {
  background-color: #2ecc71;
  color: #fff;
}

.error-message {
  background-color: #e74c3c;
  color: #fff;
}
