.admin-banned-user-container {
  padding: 20px;
}

.ban-user-form {
  margin-bottom: 20px;
}

.ban-user-form input {
  padding: 10px;
  margin-right: 10px;
}

.ban-user-form button {
  padding: 10px 20px;
}

.banned-user-item {
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.banned-user-item button {
  margin-top: 10px;
}
