.setting-push-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .back-link {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .setting-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggle-label {
    font-size: 18px;
    margin-right: 10px;
  }
  
  .switch-a {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch-a input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-a {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
  }
  
  .slider-a:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider-a {
    background-color: #2196F3;
  }
  
  input:checked + .slider-a:before {
    transform: translateX(14px);
  }
  
  .description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .enable-container {
    text-align: center;
  }
  
  .enable-container h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .enable-container p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .enable-button {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .enable-button:hover {
    background-color: #1976D2;
  }
  