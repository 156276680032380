/* 全体的にコンテナを中央に配置し、シンプルでモダンなスタイルを適用 */
.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    text-decoration: none;
    margin-top: 50px;
  }
  
  .login-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }
  
  .login-error {
    color: #ff4d4f;
    margin-bottom: 20px;
  }
  
  .login-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .login-button, .login-google-button {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 10px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .login-google-button {
    background-color: #db4437;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-icon {
    margin-right: 8px;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .login-google-button:hover {
    background-color: #c23321;
  }
  
  .login-signup-link {
    margin-top: 20px;
    color: #007bff;
  }
  
  .login-signup-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .login-signup-link a:hover {
    text-decoration: underline;
  }
  