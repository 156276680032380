.chat-not-display-container {
    max-width: 600px;
    margin: 0px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    color: #333333;
  }
  
  .hidden-chats-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .hidden-chat-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #eeeeee;
  }
  
  .hidden-chat-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    flex-grow: 1;
  }
  
  .hidden-chat-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .hidden-chat-info {
    flex-grow: 1;
  }
  
  .hidden-chat-name {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin: 0 0 5px 0;
  }
  
  .hidden-chat-last-message {
    font-size: 14px;
    color: #777777;
    margin: 0;
  }
  
  .unhide-chat-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .unhide-chat-button:hover {
    background-color: #007bff;
  }
  
  .unhide-chat-button:focus {
    outline: none;
  }
  