.group-header-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}
  
.group-header-profile-scroll-container {
    display: flex;
    align-items: center;
    padding: 10px;
}
  
.group-header-profile-icon-link {
    display: inline-block;
    margin-right: -10px; /* 負のマージンで重なるようにする */
}
  
.group-header-profile-icon {
    width: 30px; /* Adjust size as necessary */
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
