.admin-suspend-user-container {
  padding: 20px;
}

.reported-user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.reported-user-info {
  display: flex;
  align-items: center;
}

.reported-user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.suspend-button {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.suspend-button:hover {
  background-color: darkred;
}
