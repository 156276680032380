.iine-container {
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 0px;
}

.iine-toptext {
  font-size: 20px;
  font-weight: bold;
}

.likes-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.like-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.like-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.like-card-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
}

.like-card-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 25px;
}
.like-card-message {
  max-width: 300px;
}

.like-card-info {
  display: flex;
  flex-direction: column;
}

.like-card-info h3 {
  margin: 0;
  font-size: 1.2em;
}

.like-card-info p {
  margin: 5px 0 0;
  color: #666;
}

.like-card-buttons {
  display: flex;
  gap: 10px;
  margin-right: 5px;
}

.like-card-button {
  padding: 8px 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.like-card-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.iine-approve {
  background-color: white;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  color: red;
}

.iine-approve:hover {
  background-color: white;
}

.iine-reject {
  background-color: white;
  color: gray;
  font-size: 24px;
}

.reject:hover {
  background-color: white;
}
.iine-skip {
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .like-card-message {
    max-width: 140px;
  }
  .like-card-image {
    margin-right: 15px;
  }
  
}

