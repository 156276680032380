/* 共通のページコンテナ */
.group-page-custom-container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0px;
  border-radius: 8px;
  margin-bottom: 50px;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 30px;
}

/* グループカードコンテナ（横スクロール） */
.group-card-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  scroll-snap-type: x mandatory;
  margin-left: 9px;
}

/* グループカード */
.group-card {
  flex: 0 0 auto;
  width: 150px;
  margin-right: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  background-color: #fff;
  scroll-snap-align: start;
}
.already-jioined {
  background-color: #888;
}
.group-card-image {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.group-card-content {
  padding: 10px;
}

.group-card-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.group-card-description, 
.group-card-genre {
  font-size: 14px;
}

.group-card-type {
  display: inline-block;
  background-color: #e0f7fa;
  color: #007bff;
  padding: 3px 7px;
  border-radius: 15px;
  margin-top: 5px;
}

/* ボタン */
.group-card-button {
  padding: 8px 16px;
  margin-top: 10px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.yet-join {
  background-color: #007bff;
}

/* グループページのタイトル・フォーム */
.group-page-custom-title, 
.group-page-custom-subtitle {
  text-align: center;
  color: #333;
  padding-bottom: 10px;
}

.group-page-custom-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
}





.group-page-custom-file-input {
  background: #ffffff;
  border: 1px solid #ccc;
  padding: 10px;
  display: block;
  width: 80%;
  margin-top: 5px;
}


/* 検索フォーム */
.search-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  width: 90%;
  margin: 0 auto;
}

.search-input{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 48%;
}
.group-card-image, .responsive-group-card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.rindoku-card-image, .responsive-rindoku-card-image {
  width: 100%;
  height: 220px;
  border-bottom: 1px solid #ddd;
}

.search-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 29%;
}

.search-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.search-button:hover {
  background-color: #0056b3;
}

/* メニュー */
.top-menu {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 0px;
}

.top-menu-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.top-menu-link:hover {
  color: #007bff;
}

/* レスポンシブグリッドレイアウト */
.responsive-group-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 10px;
}

.responsive-group-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: left;
  padding: 0px;
}

.responsive-group-card-image {
  border-bottom: 1px solid #ddd;
}

.responsive-group-card-content {
  padding: 5px;
}

.responsive-group-card-title {
  font-size: 18px;
  margin: 4px 0;
}

.responsive-group-card-genre {
  font-size: 14px;
  color: #777;
  margin: 0px;
  padding: 0px;
}

.responsive-group-card-participants {
  font-size: 14px;
  color: #555;
  margin: 0px;
  padding: 0px;
}



/* レスポンシブメディアクエリ */
@media (max-width: 1024px) {
  .responsive-group-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 600px以下のスクリーンでも2列表示 */
@media (max-width: 600px) {
  .responsive-group-grid {
    grid-template-columns: repeat(2, 1fr); /* 2列表示に修正 */
  }
  .search-input{
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 45%;
  }
  
  .search-select {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 20%;
  }
  .search-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }

  .group-action {
    text-align: center;
    position: fixed;
    bottom: 90px;
    left: 90%;
    transform: translateX(-50%);
  }

  /* グループカード */
.group-card {
  flex: 0 0 auto;
  width: 125px;
  margin-right: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  background-color: #fff;
  scroll-snap-align: start;
}

.rindoku-card-image, .responsive-rindoku-card-image {
  width: 100%;
  height: 187px;
  border-bottom: 1px solid #ddd;
}
.group-card-title {
  font-size: 14px;
}
  
}

.group-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.group-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.group-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

.group-modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.group-modal-group-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.group-modal-group-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.group-modal-group-details {
  text-align: left;
}

.group-modal-group-details p {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.group-modal-group-details strong {
  color: #333;
}



/* モーダル全体の背景 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* モーダルコンテンツのスタイル */
.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* 柔らかいシャドウ */
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out; /* モーダルのフェードイン */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* モーダルのタイトル */
.modal-content h2 {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

/* ボタンのスタイル */
.group-create-modal-button {
  display: inline-block;
  padding: 5px 10px;
  margin: 10px;
  font-size: 16px;
  color: #007bff;;
  background-color: white;
  border: 2px solid #007bff;;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: bold;
}

.group-create-modal-button:hover {
  background-color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* ホバー時のシャドウ */
}

/* 閉じるボタン */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}










