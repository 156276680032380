.settings-container {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.bell-icon-link {
  position: relative;
}

.bell-icon-link {
  position: relative;
}

.unread-indicator {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}



.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.settings-title {
  font-size: 24px;
  font-weight: bold;
}

.bell-icon-link {
  text-decoration: none;
  color: inherit;
}

.bell-icon {
  font-size: 24px;
  cursor: pointer;
}

.settings-list {
  display: flex;
  flex-direction: column;
}

.settings-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
}

.settings-item-text {
  font-size: 18px;
}

.settings-item:hover {
  background-color: #e8e8e8;
}
