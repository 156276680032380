.admin-notification-container {
    padding: 20px;
  }
  
  .notification-form {
    margin-bottom: 20px;
  }
  
  .notification-form input,
  .notification-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .notification-form button {
    padding: 10px 20px;
  }
  
  .notification-item {
    background-color: #f8f8f8;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .notification-date {
    display: block;
    margin-top: 5px;
    font-size: 0.8em;
    color: #888;
  }
  