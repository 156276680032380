.setting-mail-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}


.setting-mail-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.current-email {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
  width: 80%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.change-email-button {
  display: block;
  width: 300px;
  padding: 10px;
  background-color: #1da1f2;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 auto;
  margin-top: 30px;;
}

.change-email-button:hover {
  background-color: #0d8bd1;
}

.success-message, .error-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.success-message {
  color: #17bf63;
}

.error-message {
  color: #e0245e;
}
