/* styles/FilteredMembers.css */
.filtered-members-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 25px;
    border-radius: 8px;
  }
  
  .filtered-members-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;

  }
  
  .filtered-members-form-group {
    margin-bottom: 15px;
  }
  
  .filtered-members-form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .filtered-members-select {
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none; /* ブラウザデフォルトのスタイルを無効にする */
    -webkit-appearance: none; /* Safari用 */
    -moz-appearance: none; /* Firefox用 */
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 10px;;
  }
  
  .filtered-members-age-group {
    display: flex;
    align-items: center;
  }
  
  .filtered-members-age-group span {
    margin: 0 10px;
  }
  
  .filtered-members-apply-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filtered-members-apply-button:hover {
    background-color: #0056b3;
  }

  .filtered-members-select {
    appearance: none; /* デフォルトの矢印を非表示にする */
    background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path fill="%23333" d="M5 7L1 2h8z"/></svg>') no-repeat right 10px center;
    background-size: 12px; /* 矢印のサイズを調整 */
    background-color: white; /* selectボックスの背景色 */
    border: 1px solid #ccc; /* ボーダー */
    border-radius: 4px; /* 角の丸み */
    padding: 10px; /* 内側の余白 */
    padding-right: 30px; /* 右側の内側余白を追加して矢印が隠れないようにする */
    font-size: 16px; /* 文字サイズ */
    -webkit-appearance: none; /* Safariのデフォルト矢印を非表示にする */
    -moz-appearance: none; /* Firefoxのデフォルト矢印を非表示にする */
    text-indent: 0.01px; /* テキストを若干インデントすることで矢印の重なりを防ぐ */
    text-overflow: ''; /* テキストの溢れを防ぐ */
  }
  
  .filtered-members-select::-ms-expand {
    display: none; /* IE用のデフォルト矢印を非表示にする */
  }
  
  
  