.setting-account-delete-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.back-link {
  text-align: left;
}

.setting-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.warning {
  text-align: left;
  margin-bottom: 20px;
}

.warning ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.warning p {
  color: #ff4d4f;
  font-weight: bold;
}

.survey {
  text-align: left;
  margin-bottom: 20px;
}

.survey p {
  font-weight: bold;
  margin-bottom: 10px;
}

.survey label {
  display: block;
  margin-bottom: 10px;
}

.other-reason-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.delete-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  background-color: #0d8bd1;
  color: #fff;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.delete-button:disabled {
  background-color: #ffcccc;
  cursor: not-allowed;
}


.error-message-a {
  color: red;
  margin-top: 20px;
  font-weight: bold;
}

.success-message {
  color: #28a745;
  margin-top: 20px;
}
/* Styles for the reason-container */
.reason-container {
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  text-align: left;
}

.reason-container p {
  font-size: 1.0em;
  margin-bottom: 15px;
  font-weight: bold;
}

.reason-container label {
  display: block;
  margin-bottom: 10px;
  font-size: 1em;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  padding-right: 30px;
}

.reason-container label input {
  position: absolute;
  left: -10px;
  top: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.reason-container input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-size: 1em;
  color: #666;
  margin-right: 15px;
}

.password-container {
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: opx;
  text-align: left;
  padding-top: opx;
  font-size: 14px;
  padding-left: 0px;
  margin-bottom: 15px;
}

.password-container p {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #333;
  padding-top: 0px;
  margin-top: 0px;
}

.password-container input[type="password"] {
  width: 70%;
  padding: 10px;
  border: 1.5px solid hsl(0, 9%, 76%);
  border-radius: 4px;
  font-size: 1em;
  color: #666;
}
