/* BookContentコンポーネント専用のスタイル */

/* 全体のスタイル */
.book-content-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 0px;
}



/* 画像のスタイル */
.book-content-image {
  float: left;
  width: 25%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* テキストコンテンツ */
.book-content-details {
  overflow: hidden; /* float解除のために追加 */
}

/* タイトル */
.book-content-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #2c3e50;
}

/* サブタイトル */
.book-content-subtitle {
  font-size: 1.1rem;
  margin-bottom: 7px;
  color: #34495e;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 2px;
}

/* テキスト */
.book-content-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #7f8c8d;
  margin-bottom: 20px;
}

/* コメントセクション */
.book-comments-section {
  clear: both; /* フロートのクリア */
  width: 100%;
  margin-top: 20px;
}

.book-comments-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.book-comments-list {
  list-style: none;
  padding: 0;
}




.book-comment-textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);

}


.book-comment-button:hover {
  background-color: #2980b9;
}
.book-comment-input {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  align-items: center; /* ボタンとテキストエリアを中央揃え */
}

.book-comment-textarea {
  width: 90%;
  height: 80px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 2px solid #bdc3c7;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #333;
  resize: none; /* ユーザーがサイズを変更できないように */
}

.book-comment-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  text-align: center; /* ボタン内のテキストを中央揃え */
}

.book-comments-title {
  margin-top: 0px;
  padding-top: 0px;
 font-size: 16px; 
}

.comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-details {
  display: flex;
  flex-direction: column;
}

.comment-author {
  font-weight: bold;
  margin-bottom: 5px;
}

.book-comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.participants-info {
  margin-top: 10px;
  padding: 5px;
}

.participants-title {
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.participants-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.participant-item {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 0px 2px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.participant-item:hover {
  background-color: #f0f0f0;
}

.participant-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px;
  padding: 0px;
}

.participant-name {
  font-size: 1em;
  font-weight: 500;
}

.add-to-favorites-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  margin-top: auto; /* ボタンを下部に固定 */
  font-size: 15px;
  font-weight: bold;
  width: 90%;
  border-radius: 10px;
}