.call-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.recipient-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.recipient-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #ccc;
}

.recipient-info span {
  font-size: 1.2em;
  font-weight: bold;
}

.audio-controls {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.audio-controls button {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.audio-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.audio-controls button:not(:disabled) {
  background-color: #4caf50;
  color: white;
}

.audio-controls button:not(:disabled):hover {
  background-color: #45a049;
}

.reset-button {
  background-color: #f44336 !important;
}

.reset-button:hover {
  background-color: #e53935 !important;
}

.answer-button {
  background-color: #008CBA !important;
}

.answer-button:hover {
  background-color: #007B9E !important;
}
