.group-create-custom-form {
    margin: 0px;
    padding: 0xp;
    text-align: left;
}

.group-createing-header {
margin: 0px;
padding: 0px;
}


/* フォーム要素 */
.group-page-custom-input, 
.group-page-custom-textarea, 
.group-page-custom-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.group-page-custom-input:focus, 
.group-page-custom-textarea:focus, 
.group-page-custom-select:focus {
  border-color: #0056b3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.25);
}

.group-page-custom-textarea {
  height: 50px;
  resize: vertical;
}

/* 送信ボタン */
.group-page-custom-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 25px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 3%;
  margin-top: 20px;
}

.group-page-custom-button:hover {
  background-color: #007bff;
}

.group-page-custom-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 送信ボタン */
.group-invite-custom-button {
  background-color: rgb(83, 82, 82);
  color: #ffffff;
  padding: 5x 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.group-invite-custom-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.group-create-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease;
  border: 1px solid rgb(182, 180, 180);
}

.group-create-file-upload:hover {
  background-color: white;
}

.group-create-file-upload input[type="file"] {
  display: none;
}

.group-create-image-preview {
  margin-top: 10px;
  margin-left: 20px;
}

.group-create-image-preview img {
  width: 60px; /* サムネイルの幅 */
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -15px;

}

.group-create-horizontal {
  display: flex;
  align-items: center; /* アイテムを垂直方向で中央揃え */
  gap: 10px; /* h3とinputの間にスペースを追加 */
  width: 90%;
  margin-top: 25px;
  margin-left: 0px;
}

.group-create-horizontal h3 {
  margin: 0; /* デフォルトの余白を除去 */
  white-space: nowrap; /* テキストを1行に収める */
  font-size: 16px;
}

.group-page-custom-input {
  padding: 8px; /* 内側の余白を追加 */
  position: relative;
  top: 10px;
  width: 40%;
}

.group-page-custom-select {
  padding: 8px; /* 内側の余白を追加 */
  position: relative;
  top: 10px;
}
.group-select-form {
  width: 150px;
}



/* モーダルオーバーレイのスタイル */
.group-create-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* モーダルコンテンツのスタイル */
.group-create-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

/* モーダルを閉じるボタンのスタイル */
.group-create-modal-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* フィルターセクションのスタイル */
.group-create-modal-filters {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 6px;
}

/* フォームグループのスタイル */
.group-create-modal-form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

/* 年齢グループのスタイル */
.group-create-modal-age-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

/* フィルター用セレクトボックスのスタイル */
.group-create-modal-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 35%;
  margin-top: 5px;
}

/* フィルター適用ボタンのスタイル */
.group-create-modal-apply-filters-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.group-create-modal-apply-filters-button:hover {
  background-color: #007bff;
}

/* メンバーリストのスタイル */
.group-create-modal-member-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

/* メンバーカードのスタイル */
.group-create-modal-member-card {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* メンバーアバターのスタイル */
.group-create-modal-member-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* メンバーディテールのスタイル */
.group-create-modal-member-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* 招待ボタンのスタイル */
.group-create-modal-invite-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.group-create-modal-invite-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.group-create-modal-invite-button:hover:not(:disabled) {
  background-color: #007bff;
}

/* 招待されたメンバーリストのスタイル */
.group-create-modal-selected-members {
  list-style-type: none;
  padding: 0;
  width: 70%;
  margin: 0 auto;
}

/* 招待されたメンバーアイテムのスタイル */
.group-create-modal-selected-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* メンバー削除ボタンのスタイル */
.group-create-modal-remove-button {
  background-color: #ff4d4f;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.group-create-modal-remove-button:hover {
  background-color: #ff7875;
}

/* メンバー検索入力フィールドのスタイル */
.group-create-modal-input {
  width: 60%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

