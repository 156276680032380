.notifications-container {
    padding: 10px;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
  }
  
  .notifications-container h2 {
    margin-bottom: 20px;
  }
  
  .notification-item-a {
    padding: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(217, 215, 215);

  }
  li {
    list-style:none
  }

  .notification-content-container {
    margin: 0 auto;
    width: 75%;
    margin-top: 10px;
    text-align: left;
  }
  
  .notification-date {
    display: block;
    margin-top: 5px;
    font-size: 0.8em;
    top: -10px;
    position: relative;;
    padding-right: 10px;
  }
  .notification-item-a {
    align-items: left;
    background-color: white;
    margin-left: -70px;;
  }
  
  .notification-content {
    width: 100%;
    background-color: white;
  }
  
  .notification-date {
    margin-left: auto;
  }
  
  h3 {
    margin: 0;
  }
  .unread-tag {
    background-color: red;
    color: white;
    padding: 2px;
    border-radius: 50%;
    margin-left: -10px;
    margin-top: -5px;
    height: 4px;
    width: 4px;
    position: relative;
    top: 5px;
  }
  
  