
.your-group-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex; /* 横並びにする */
  align-items: center; /* コンテンツを中央揃え */
  padding: 0px;
  width: 100%;
  margin-left: 10px;
  margin: 0 auto;
  padding-left: 10px;
  margin-bottom: 20px;
}

.your-group-card-image {
  width: 60px; /* 画像の固定幅 */
  height: 60px;
  object-fit: cover;
  border-radius: 8px; /* 角を丸める */
  margin: 0px;
  position: relative;
}

.your-group-card-content {
  padding: 5px;
  flex-grow: 1; /* コンテンツが画像の右側に広がるように */
  padding-left: 13px;
}

.your-group-card-title {
  font-size: 17px;
  margin: 0;
  width: 250px;
}

.your-group-card-genre {
  font-size: 12px;
  color: #777;
  margin-left: 12px;
}
.group-menu-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  padding-right:7px;
  position: relative;
  top: 5px;
}

.group-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 10000;
  right: calc(45% - 325px); 
}

.group-menu p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}

.group-menu p:hover {
  background-color: #f0f0f0;
}

/* 申請者リストのスタイル */
.pre-participants-list {
  margin-top: 15px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.pre-participants-list h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.pre-participant-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.pre-participant-item:last-child {
  margin-bottom: 0;
}

.pre-participant-item span {
  font-size: 14px;
  color: #555;
}

.pre-participant-item button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.pre-participant-item button:hover {
  background-color: #0056b3;
}

.pre-participant-item .deny-button {
  background-color: #ff4d4d;
  margin-left: 10px;
}

.pre-participant-item .deny-button:hover {
  background-color: #cc0000;
}.your-group-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;
  width: 97%;
}

.pre-participants-list-container {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.pre-participants-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pre-participant-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.pre-participant-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.pre-participant-name {
  font-size: 14px;
  font-weight: bold;
}

.approve-button, .deny-button {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.approve-button {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.deny-button {
  background-color: #f44336;
  color: white;
  border: none;
}

.group-share-x-icon, .group-share-line-icon {
  font-size: 15px;
  cursor: pointer;
  margin-right: 6px;
  margin-left: 2px;
  position: relative;
  top: 5px;

}
.group-share-line-icon {
  color: #00c300; /* Line's green */
}
.group-icons-span {
  font-size: 13px;
  color: gray;
}
@media (max-width: 600px) {
  .group-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 4px;
    z-index: 10000;
    right: 10px; 
  }

  .your-group-card-title {
    font-size: 15px;
    margin: 0;
    width: 120px;
  }

  .view-group-button {
    display: inline-block;
    padding: 5px 0px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 11px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    border: solid 1px black;
    margin-right: 4px;
    min-width: 100px;
  }
}


.view-group-button {
  display: inline-block;
  padding: 5px 0px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 12px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  border: solid 1px black;
  margin-right: 4px;
  min-width: 100px;
}

.view-group-button:hover {
  background-color: white;
  color: black;
  font-weight: bold;
}

.bosyu-group-button {
  display: inline-block;
  padding: 5px 6px;
  background-color: white;
  color: #007bff;;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 12px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  margin-right: 20px;
  border: solid 1px #007bff;;
}

.bosyu-group-button:hover {
  background-color: #007bff;;
  color: white;
  font-weight: bold;
}
