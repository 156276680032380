.setting-inquiry-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
}



.back-link::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid black;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.settings-title {
  font-size: 24px;
  font-weight: bold;
}

.profile-input, .profile-textarea {
  width: calc(100% - 20px);
  max-width: 500px;
  padding: 12px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.profile-input:focus, .profile-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.profile-textarea {
  resize: vertical;
}

.success-message {
  color: green;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}


.save-button:hover {
  background-color: #218838;
}
