.admin-dashboard-container {
  padding: 20px;
}

.admin-dashboard-container h2 {
  margin-bottom: 20px;
}

.admin-dashboard-container ul {
  list-style-type: none;
  padding: 0;
}

.admin-dashboard-container li {
  margin-bottom: 10px;
}

.admin-dashboard-container a {
  text-decoration: none;
  color: #007bff;
}

.admin-dashboard-container a:hover {
  text-decoration: underline;
}
