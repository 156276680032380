.report-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .reported-user-image {
    width: 120px;
    margin: 0 auto;
  }
  .report-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .report-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 20px;
  }
  
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 auto;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
    margin: 0 auto;
  }

  
  .submit-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 30px;
  }
  .approve {
    position: relative;
    left:-60px;
  }
  .reject {
    position: relative;
    left:-60px;
    font-size: 30px;
    background-color: gray;
  }
  
  .submit-button:disabled {
    background-color: #aaa;
  }
  
  .submit-button:not(:disabled):hover {
    background-color: #0056b3;
  }
  
  /* モーダルのオーバーレイ */
.like-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* モーダル本体 */
.like-modal {
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  max-width: 400px;
  width: 80%;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

/* モーダルのタイトル */
.like-modal h3 {
  margin: 0 0 15px;
  font-size: 14px;
  color: #333333;
  text-align: center;
}

/* テキストエリア */
.like-modal-textarea {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  font-size: 14px;
  resize: none;
  height: 60px;
}

/* モーダルのボタンコンテナ */
.like-modal-buttons {
  display: flex;
  text-align: center;
  margin: 0 auto;
}

/* モーダルのボタン */
.like-modal-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin: 5px;
}

/* プライマリボタンのスタイル */
.like-modal-button-primary {
  background-color: #007bff;
  color: #ffffff;
}

.like-modal-button-primary:hover {
  background-color: #0056b3;
}

/* セカンダリボタンのスタイル */
.like-modal-button-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.like-modal-button-secondary:hover {
  background-color: #5a6268;
}

/* モーダルを閉じるボタン */
.like-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

