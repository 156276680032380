/* リスト全体のスタイル */
.participant-card {
  display: flex;
  justify-content: flex-end; /* 右寄せ */
  align-items: center;
  padding: 8px 15px;
  margin: 10px 0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.participant-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* ユーザー情報部分を右に寄せる */
.participant-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* ユーザー写真のスタイル */
.participant-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  position: relative;
  top: 14px;
}

/* ユーザー詳細情報のスタイル */
.participant-details {
  display: flex;
  text-align: left; /* テキストを右寄せ */
}

.participant-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* 右端に揃える */
}

.participant-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.participant-location,
.participant-major {
  font-size: 14px;
  color: #666;
  margin-bottom: 3px;
}

/* ボタンのスタイル */
.participant-actions {
  display: flex;
  gap: 10px;
}

.btn-approve,
.btn-reject {
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.btn-approve {
  background-color: #007bff;
  color: white;
}

.btn-approve:hover {
  background-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 128, 0, 0.2);
}

.btn-reject {
  background-color: gray;
  color: white;
}

.btn-reject:hover {
  background-color: gray;
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.2);
}
