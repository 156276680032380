.notification-detail-container {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}

.notification-detail-container h2 {
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.notification-detail-container p {
  margin-bottom: 20px;
}

.notification-detail-container .notification-date {
  display: block;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #888;
  border-bottom: 1px solid rgb(234, 231, 231);
  padding-bottom: 10px;
}


.notification-detail-container button {
  padding: 10px 20px;
}
