.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    text-align: center;
    margin-top: 50px;
  }
  
  .reset-password-title {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
  }
  
  .instruction {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .message {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .success {
    color: #4caf50;
  }
  
  .error {
    color: #f44336;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .input {
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .back-to-login {
    margin-top: 20px;
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  