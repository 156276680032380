/* モーダルの背景 */
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* 背景の透過 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* モーダルのコンテンツ */
  .modal-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 80%;
    max-width: 600px;
    position: relative;
  }
  
  /* 閉じるボタン */
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #666;
  }
  
  /* 見出し */
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* テキストエリア */
  .thread-input {
    width: 95%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: vertical;
    min-height: 100px;
    margin-bottom: 20px;
  }
  
  /* 数式入力ボタン */
  .thread-submit-button {
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .thread-submit-button:hover {
    background-color: #005a9e;
  }
  
  /* ファイルアップロードアイコン */
  .file-upload-icons label {
    cursor: pointer;
  }
  
  .file-upload-icons svg {
    fill: gray; /* アイコンカラー */
    transition: fill 0.3s ease;
  }
  
  .file-upload-icons svg:hover {
    fill: #005a9e;
  }
  
  /* エラーメッセージ */
  .error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  .button-file-container {
    display: flex;
    align-items: center;
    gap: 10px; /* アイテム間のスペースを設定 */
  }
  
  .file-upload-icons {
    display: flex;
    gap: 10px; /* アイコン間のスペース */
  }

  .react-quill-editor {
    max-height: 150px; /* 高さを調整 */
    overflow-y: auto; /* 行数が増えるとスクロール */
  }

  .thread-math-assist-button {
    background-color: gray;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 13px;
  }
  
    