.profile-edit-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 300px;
  font-size: 20px;
  text-align: left;
  width: 90%;
}
.header {
  display: flex;
  line-height: 30px;
}

.back-link-cont {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  position: relative;
  font-size: 24px;
  color: black;
  line-height: 30px;
}

.back-link::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
/* ProfileEdit.css */
.darkened {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  padding-top: 600px;
}


.settings-title-cont {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 0px;
}


.profile-picture-container {
  margin-bottom: 10px;
  text-align: center;
  
}

.profile-picture {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  margin-top: 50px;
}

.sub-profile-picture-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.sub-profile-picture-container {
  text-align: center;
}

.sub-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin: 10px 0;
}

.custom-file-upload {
  display: inline-block;
  padding: 3px 6px;
  cursor: pointer;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  font-size: 12px;
  margin: 0 auto;
  width: 90px;
  margin-top: 15px;
  text-align: center;
  font-size: 10px;
}

.file-input {
  display: none;
  margin: 0 auto;
  width: 300px;
  text-align: center;
}

.profile-input, .profile-textarea {
  margin-top: 10px;
}
.profile-textarea { 
  height:150px;
}
.profile-input:focus, .profile-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  height:300px;
}

.form-group-a {
  display: flex;
  justify-content: space-between; /* 左右にスペースを均等に分ける */
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  font-size: 15px;
}

.form-group-a label {
  font-size: 16px;
  margin-bottom: 5px;
  width: 60px;
  margin-top: 0px;;
}


.profile-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}


label {
  font-size: 16px;
}


.button-container {
  display: flex;
  justify-content: space-between;
}

block-label {
  display: flex;
  justify-content: space-between;
}


.save-button,
.logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 17px;
  font-weight: bold;
  width: 70%;;
  margin: 0 auto;
}

.save-button {
  background-color: #007bff;
  color: #fff;
  margin-top: 20px;;
}

.save-button:hover {
  background-color: #0056b3;
}

.logout-button {
  background-color: #dc3545;
  color: #fff;
}

.logout-button:hover {
  background-color: #c82333;
}

.crop-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  z-index: 9900;
  width: 300px;
  height: 500px;
}

.crop-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 380px;
}

.crop-button1 {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
  z-index: 9900;
}

.block-label {
  display: flex;
  justify-content: space-between;
}

.birthday-container {
  font-size: 14px;
  position: relative;
  top: -6px;;
}

.crop-button1:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .save-button,
  .logout-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .crop-controls {
    flex-direction: column;
  }

  .crop-button1 {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* ハッシュタグのスタイル */
.hashtag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
  margin-top: 0px;
  padding-top: 0px;
  top: -27px;
  position: relative;
}

.hashtag {
  background-color: rgb(77, 74, 74);
  color: #fff;
  padding: 5px 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0px;
}

.hashtag button {
  background: none;
  border: none;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}

.hashtag-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
}

.hashtag-input {
  width: 150px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: auto;

}

.hashtag-add-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 15px;
  width:80px;
}

.hashtag-add-button:hover {
  background-color: #0056b3;
}

/* アップロード中のアニメーション */
.loading-spinner {
  margin-top: 10px;
  font-size: 14px;
  color: #007bff;
}

.image-container {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 0px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 50%;
}

.sub-profile-picture-row {
  display: flex;
  gap: 10px;
}

.profile-picture-container,
.sub-profile-picture-container {
  position: relative;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 背景を暗くする */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 他のコンテンツの上に表示するためのz-index */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1010; /* overlayよりも上に表示 */
}
