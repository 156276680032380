.setting-pass-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

.back-link {
  margin-bottom: 20px;
}

.setting-title {
  margin-bottom: 20px;
  text-align: center;
}

.setting-pass-form .form-group {
  margin-bottom: 15px;
}

.setting-pass-form label {
  display: block;
  margin-bottom: 5px;
}

.setting-pass-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 20px;
}
