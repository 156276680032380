.chat_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}



.chat_header {
    position: relative;
    padding: 10px;
    background-color: white;
    color: black;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px whitesmoke solid;
    padding-left: 20px;;
    margin-bottom: 20px;
  }
  
  .header_menu {
    position: relative;
    cursor: pointer;
  }
  
  .header_menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px;
    font-size: 13px;;
    color: black;
  }
  
  .header_menu-item {
    padding: 10px 15px;
    cursor: pointer;
    color: black;
  }
  
  .header_menu-item:hover {
    background: #f1f1f1;
    color: black;
  }
  

.chat_messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.chat_message {
    display: flex;
    margin: 10px 0;
    align-items: center;
}

.me {
    justify-content: flex-end;
}

.other {
    justify-content: flex-start;
}

.chat_message-content {
    max-width: 50%;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    background-color: #f1f1f1;
    margin-bottom: 25px;
    margin-left: 10px;
    width: 40%;
    padding-left: 29px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 12px;
    margin-bottom: 35px;
    text-align: left;
    font-size: 16px;
}

.me .chat_message-content {
    background-color: #007bff;
    margin-right: 10px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    padding-left: 10px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    margin-right: 20px;
    padding-top: 14px;
    width: 45%;
}

.chat_avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    top: -39px;
    left: 20px;
    z-index: 999;
    border: #ccc 1px solid;
}

.chat_message-sender {
    font-weight: bold;
    margin-bottom: 15px;
}

.chat_message-text {
    margin-bottom: 5px;
    position: relative;
    top: 0px;
    left: -4px;
}

.me .chat_message-text {
    top: 0px;
    left: 4px;
}

.chat_message-image {
    max-width: 80%;
    max-height: 200px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 5px;
    margin: 0 auto;
    text-align: center;
    margin-left: 10px;
    margin-top: 10px;
}

.chat_message-time {
    font-size: 0.75em;
    color: gray;
    position: absolute;
    bottom: -15px;
    right: 0px;

}

.chat_message-options {
    position: absolute;
    top: 3px;
    right: 7px;
    cursor: pointer;
}

.chat_message-menu {
    display: none;
    position: absolute;
    top: 20px;
    right: 0px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 100px;
    color: black;
    font-weight: normal;
}
.call-link {
    font-size: 16px;
    position: relative;
    right: 12px;
    top: -2px;
    color: black;
}

.chat_message-menu.open {
    display: block;
}

.chat_message-menu-item {
    padding: 8px 12px;
    cursor: pointer;
}

.chat_message-menu-item:hover {
    background: #f1f1f1;
}

.chat_input-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f1f1f1;
    position: relative;
}

.chat_image-preview {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #e9ecef;
    border-radius: 4px;
    margin-bottom: 10px;
}

.chat_image-preview img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 4px;
    margin-right: 10px;
}

.chat_image-preview button {
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}

.chat_image-preview button:hover {
    background: #c82333;
}

.chat_input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.chat_input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.chat_image-upload {
    margin-right: 10px;
    cursor: pointer;
}

.chat_send-button {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.chat_send-button:hover {
    background: #0056b3;
}

.expanded_image_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.expanded_image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}

.popup_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* 半透明の黒背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out; /* フェードインアニメーション */
  }
  
  .popup_container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 90%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    animation: popupSlideIn 0.3s ease-in-out; /* スライドインアニメーション */
  }
  
  .popup_container p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .popup_link {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 10px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 10px;
  }
  
  .popup_link:hover {
    background-color: #0056b3;
  }
  
  .popup_close-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: gray;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup_close-button:hover {
    background-color: #c82333;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes popupSlideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
